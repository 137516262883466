@import "~bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,300;0,500;0,600;0,700;0,900;1,300&family=Quicksand:wght@400;500;600&display=swap');


// Components import
@import "shared/UI/Spinner/Spinner.scss";

html {
  height: 100% !important;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

body {
  height: 100%;
  background: radial-gradient(116.48% 209.06% at 80.47% 81.35%,#dff7ff 0,hsla(0,0%,100%,0) 100%),#fff;
}

#root {
  position: relative;
  height: 100%;
  //overflow-x: hidden;
}

.root-wrap {

  .hw-100 {
    height: 100vh !important;
  }

  @media (max-width: 580px) {
    height: 100% !important;
  }
}

.App {
  //min-height: 100vh;
  height: 100%;
  position: relative;
  background: radial-gradient(116.48% 209.06% at 80.47% 81.35%, #DFF7FF 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF;
  overflow-x: hidden;

  @media (max-width: 580px) {
    height: auto;
  }

  .header {
    @media (max-width: 992px) {
      z-index: 1000;
      position: relative;
    }
  }


  &-background {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%; // (90%) before
    max-height: 900px; // before this wasn't there
    z-index: 0;

    img {
      height: 100%;
      //max-height: 100vh;
    }

    @media (max-width: 768px) {
      left: -1px;
      top: 2px;
    }

    @media (max-width: 580px) {
      width: 509px;
      height: 566px;
      left: -1px;
      top: 2px;

      opacity: 0.5;
    }
  }

  .DesktopOnly {
    @media (max-width: 992px) {
      display: none;
    }
  }

  p {
    font-family: Quicksand;
    font-weight: normal;
  }

}

.DesktopOnly {
  @media (max-width: 992px) {
    display: none !important;
  }
}

.App-client {
  background: #FAFCFD;

  .header {
    z-index: 15;
    @media (max-width: 480px) {
      margin-bottom: 0;
    }
  }

  .text-blue {
    color: #00B8F5;
  }


  .text-purple {
    color: #B800F5;
    font-family: Quicksand;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
  }
}


@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(1020px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}


.wrapper {
  height: 100% !important;

  @media (max-width: 992px) {
    min-width: 100vw;
    align-items: center;
    margin: 0;
    padding: 0;
    //height: auto !important;
  }

  @media (max-width: 580px) {
    //display: grid !important;
    //grid-template-columns: 1fr;
    //grid-gap: 10px;
    //grid-template-areas:
    //"a"
    //"b"
    //"c"
    //"d";
    height: auto !important;
  }
}

.container-section {
  //padding: 100px 15px;
  max-width: 100%;
  height: 100%;
  transition: all .2s linear;
  display: flex;
  flex-direction: column;



  img {
    // width: 110%;
    height: 450px;
    max-height: 450px;
    transition: height .2s linear;

    @media (max-width: 1480px) {
      height: 380px;
    }

    @media (max-width: 1280px) {
      height: 340px;
    }

    @media (max-width: 992px) {
      height: auto;
      max-height: unset;
    }

  }

  @media (max-width: 1290px) {
    //padding-bottom: 30px;
  }

  @media (max-width: 992px) {
    //padding: 0px 25px 30px 25px ;
    //height: auto;
  }
}



.btn-purple {
  padding: 16px 32px;
  background: linear-gradient(90deg, #B800F5 0%, #7D00A6 100%);
  box-shadow: 0px 10px 35px rgba(112, 0, 149, 0.28);
  border-radius: 4px;
  color: #fff;
  margin: auto 10px;
  font-family: Quicksand;
  font-style: normal;
  font-weight: 900;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  transition: all .2s ease-in-out;

  &:hover {
    color: #fff;
    box-shadow: 0px 10px 35px rgba(112, 0, 149, 0.48);
  }

  .loader {
    font-size: 14px;
    line-height: 16px;
    margin: 9px 55px;
    color: #fff;
  }

  @media (max-width: 992px) {
    margin: auto;
    position: relative;
    top: -20px;
    display: block;
  }
}

.btn-purple-mobile {
  top: unset;
  margin: auto 10px;
}

.smallText {
  display: block;
  text-transform: none;
  font-size: 12px;
  font-weight: normal;
}

.btnLast {
  padding: 10px 30px !important;
  font-size: 14px !important;
}




.intro {
  height: 100%;

  .wawe {
    @media (max-width: 1024px) {
      img {
        height: 300px;
      }
    }

    @media (max-width: 580px) {
      img {
        height: 300px;
      }
    }
  }

  .mobile-alignment {
    @media (max-width: 992px) {
      margin-top: -140px;
      z-index: 5;

      img {
        max-height: 230px;
        position: relative;
        left: 20px;
      }
    }

    @media(max-width: 580px) {
      margin-top: -155px;
      img {
        max-height: 200px;
      }
    }
  }





  .mobile-d-flex {

    @media (max-width: 1024px) {
      p {
        font-size: 14px;
      }
    }

    @media (max-width: 992px) {
      display: flex;
      justify-content: center;
    }

    @media (max-width: 580px) {
      justify-content: center;
      flex-direction: column;

      .footer-icon {
        display: block;
        margin-left: auto !important;
        margin-right: auto !important;
      }

      p {
        text-align: center;
      }
    }



    .footer-icon {
      height: 40px;
      img {
        height: 100%;
      }

      @media (max-width: 1024px) {
        height: 32px;
      }

      @media (max-width: 992px) {
        margin-right: 25px;
        position: relative;
        top: 5px;
      }

      @media (max-width: 580px) {
        position: relative;
        top: 12px;
      }
    }
  }


  .winner-wrap {
    background: #FFFFFF;
    box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 10px 25px;
    animation: fadeInRight 1.3s ease;
    position: absolute;
    height: 100px;
    right: 0;
    top: 20%;
    img {
      height: 80px;

      @media (max-width: 1280px) {
        height: 60px;
      }
    }

    @media (max-width: 1024px) {
      height: auto;
      top: 15%;
    }

    @media (max-width: 992px) {
      display: none;
    }
  }

  .what-we-do {
    display: flex;
    justify-content: space-between;
    position: relative;
    padding: 75px 0 10px 0;
    top: -50px;
    img {
      position: absolute;
      top: -50px;
      left: 25px;
      width: 95%;
      z-index: 0;
      transition: all .2s linear;

      @media (max-width: 1480px) {
        top: -20px;
      }

      @media (max-width: 1280px) {
        top: 0;
      }
    }

    .circle {
      position: relative;
      z-index: 2;
      background: #FFFFFF;
      border: 8px solid rgba(0, 0, 0, 0.02);
      border-radius: 50%;
      height: 120px;
      width: 120px;
      //padding: 35px 25px;

      h3 {
        font-family: Quicksand;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: #000000;
        position: relative;
        top: 50%;
        transform: translateY(-50%);

        @media (max-width: 1024px) {
          font-size: 14px;
        }
      }

      @media (max-width: 1024px) {
        height: 100px;
        width: 100px;
      }

      span {
        display: block;
        background: -webkit-linear-gradient(#0C8FDF, #B800F5);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  &-textBox {
    position: relative;
    //left: -50px;
    transition: all .2s linear;

    @media (max-width: 1480px) {
      //left: -90px;
    }

    @media (max-width: 1280px) {
      //left: -120px;
    }

    @media (max-width: 1180px) {
      //left: -15px;
    }
  }

  &-heading {
    font-family: Quicksand;
    font-style: normal;
    //font-weight: 600;
    font-size: 42px;
    line-height: 64px;
    /* or 133% */

    text-align: center;
    color: #000000;

    transition: all .2s linear;

    @media (max-width: 1480px) {
      font-size: 28px;
      line-height: 42px;
    }

    @media (max-width: 1280px) {
      font-size: 36px;
      line-height: 58px;
    }

    @media (max-width: 1180px) {
      font-size: 34px;
    }

    @media (max-width: 1024px) {
      font-size: 24px;
      line-height: 36px;
    }

    @media (max-width: 992px) {
      font-size: 30px;
      line-height: 42px;
    }

    @media (max-width: 580px) {
      font-size: 20px;
      line-height: 32px;
    }
  }

  .btn-purple {
    padding: 25px 50px;
    position: relative;
    z-index: 5;
    font-family: Quicksand;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 23px;
    /* identical to box height */

    text-transform: uppercase;
    transition: all .2s linear;

    @media (max-width: 1480px) {
      padding: 16px 32px;
      top: -25px;
      font-size: 15px;
    }

    @media (max-width: 1248px) {
      //padding: 20px 45px;
      font-size: 16px;
    }

    @media (max-width: 1024px) {
      font-size: 14px;
      padding: 16px 32px;
      top: 20px;
    }

    @media (max-width: 992px) {
      padding: 16px 32px;
      font-size: 14px;
    }

    @media (max-width: 580px) {
      //padding: 16px 32px;
      //font-size: 14px;
      top: -10px;
    }
  }

  &-footer {
    padding: 15px 140px;
    color: #5A7984;

    @media (max-width: 1180px) {
      //padding: 15px 50px;
    }
  }

  @media (max-width: 1480px) {
    padding: 40px 15px;
    justify-content: space-between !important;
  }

  @media (max-width: 1320px) {
    margin-left: 50px;

  }

  @media (max-width: 1280px) {
    //padding: 20px 15px;
    padding: 0;
    margin:0;
  }

}

.icon-row {
    color: #5a7984 !important;

  @media (max-width: 1024px) and (min-width: 992px) {
    position: relative;
    bottom: -30px;
  }
}


// slide view
.slide-routes {
  height: 100%;
  overflow: unset !important;

  //@media(max-width: 992px) {
  //  display: block !important;
  //}
}



.loanOptions-container {
  padding-left: 100px;
  padding-right: 100px;

  @media (max-width: 1290px) {
    padding: 50px 25px 10px 25px;
  }

  @media (max-width: 768px) {
    padding-left: 25px;
    padding-right: 25px;
  }

  @media (max-width: 580px) {
    padding: 0px 25px 30px 25px;
    margin-top: -30px;
  }

  img {
    transition-property: all;
    transition-duration: .3s;
    transition-delay: 0.4s;
    transition-timing-function: linear;
  }

  .show {
    opacity: 1;
    @media (max-width: 580px) {
      height: unset !important;
    }
  }

  .loanWrap {

    .col-lg-7 {
      @media (max-width: 1280px) {
        padding-left: 30px;
      }

      @media (max-width: 992px) {
        padding-left: 15px;
      }
    }

    @media(max-width: 580px) {
      margin-top: -20px;
    }
  }

  .hide {
    opacity: 0;
    @media (max-width: 580px) {
      height: 0;
    }
  }

  .text-small {
    position: relative;
    font-size: 12px;
    top: -8px;
  }

}



//.input-range {
//  &__track--background {
//    background: #D5EAF1 !important;
//
//    &:before {
//      content: '';
//      display: block;
//      background: url("../../../../assets/UserAssets/markers.png");
//      background-repeat: no-repeat;
//      background-size: cover !important;
//      background-position: center;
//      height: 25px;
//      position: relative;
//      top: -10px;
//
//      @media (max-width: 580px) {
//        background: url("../../../../assets/UserAssets/mobileMarkers.png");
//      }
//    }
//  }
//
//  &__track--active {
//    background: none !important;
//  }
//
//  &__slider:active {
//    transform: scale(1) !important;
//  }
//
//  &__slider {
//    background: none !important;
//    border: none !important;
//    border-radius: 0 !important;
//    height: 4rem !important;
//    width: 4rem !important;
//
//    &:before {
//      content: "";
//      display: block;
//      background: url("../../../../assets/UserAssets/rangeSlider.png") !important;
//      background-repeat: no-repeat !important;
//      height: 100px;
//      width: 100px;
//      position: relative;
//      top: -60px;
//
//      @media (max-width: 580px) {
//        background: url("../../../../assets/UserAssets/mobileRangeSlider.png") !important;
//        background-repeat: no-repeat !important;
//        top: -45px;
//      }
//    }
//
//  }
//
//  &__label {
//    top: 35px;
//    font-family: Quicksand !important;
//    font-style: normal;
//    font-weight: bold;
//    font-size: 16px !important;
//    line-height: 20px;
//    /* identical to box height */
//
//
//    color: #A4C9D6 !important;
//  }
//
//  &__slider-container {
//    .input-range__label {
//      display: none;
//    }
//  }
//}
//
//.days_slider {
//
//  .input-range__track--background {
//    background: url("../../../../assets/UserAssets/newRangeSlider.png") no-repeat !important;
//    //width: 100%;
//    height: 55px;
//    background-size: cover !important;
//
//    &:before {
//      opacity: 0;
//    }
//
//    @media (max-width: 580px) {
//      background: #D5EAF1 !important;
//      height: .3rem;
//
//      &:before {
//        content: '';
//        display: block;
//        background: url("../../../../assets/UserAssets/mobileMarkers.png") !important;
//        background-repeat: no-repeat;
//        background-size: cover !important;
//        background-position: center;
//        height: 25px;
//        position: relative;
//        top: -10px;
//        opacity: 1;
//      }
//    }
//  }
//}

.notification {
  transition: all .2s ease-in-out;
  margin-top: 10px;
  height: 50px;

  &-icon {
    height: 30px !important;
    margin-right: 10px;
  }

  &-text {
    font-family: Quicksand;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    /* identical to box height, or 175% */


    /* šedá */

    color: #5A7984;
    @media (max-width: 580px) {
      font-size: 14px;
      line-height: 24px;
    }
  }

  @media (max-width: 580px) {
    //height: 0px;
  }

}



.calcSummary {
  padding: 25px;
  background: #FFFFFF;
  box-shadow: 0px 25px 60px rgba(0, 84, 112, 0.15);
  border-radius: 10px;
  margin-left: 50px;

  &-blue {
    font-family: Quicksand;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    /* identical to box height */


    /* modrá */

    color: #00B8F5;
  }

  &-list {
    //margin-top: 20px;
    padding: 0;
    list-style: none;

  }

  &-listItem {
    padding: 15px 0;
    border-bottom: 1px solid rgba(#CCDFE5, .8);
    font-family: Quicksand;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    /* identical to box height */


    /* šedá */

    color: #5A7984;

    &:first-child {
      border-top: 1px solid rgba(#CCDFE5, .8);
    }

    &::marker {
      content: "";
      font-size: 0;
    }

    .text-bold {
      font-weight: bold;
      font-size: 15px;
      text-align: right;
      display: block;
    }
  }

  @media (max-width: 1350px) {
    margin-left: 0;
  }

  @media (max-width: 992px) {
    margin-left: 0;
    //margin-top: 50px;
  }

  @media (max-width: 340px) {
    margin-top: 75px;
  }
}

.chooseValue {
  &-headingBox {
    h2 {
      font-family: Quicksand;
      font-style: normal;
      font-weight: normal;
      padding: 0;
      @media (max-width: 1290px) {
        font-size: 26px;
      }

      @media (max-width: 992px) {
        font-size: 22px;
      }
      @media (max-width: 580px) {
        font-size: 16px;
      }
    }
  }

  @media (max-width: 992px) {
    margin-bottom: 50px !important;
  }

  @media (max-width: 580px) {
    margin-bottom: 20px !important;
  }

}

.priceValueRed {
  color: red;
}

.hideNotification {
  height: 0;
  opacity: 0;
}

.loanOptions-thumbDown {
  position: absolute;
  height: 250px !important;
  width: auto;
  top: 0;
  left: 50px;
  z-index: -1;
  opacity: 0;
  //transition: all .2s linear !important;
  transition-property: all;
  transition-duration: .3s;
  transition-delay:0.4s;
  transition-timing-function: linear;
}


.showThumb {
  opacity: 1;
  left: -5px;
  top: -120px;
  transition-property: all;
  transition-duration: .3s;
  transition-delay:0.4s;
  transition-timing-function: linear;
  //transition: all .2s linear !important;
  //transition-delay: .3s;

  @media (max-width: 1350px) {
    left: -50px;
  }

}

.red-text {
  color: #F77070;
}


.inputRange-root {

  .chooseValue-headingBox {
    margin-bottom: 50px;

    @media (max-width: 580px) {
      margin-bottom: 15px !important;
    }
  }

  .choosePrice {
    //margin-bottom: 50px;
    transition: margin-top 2s .5s;
    position: relative;

    @media (max-width: 580px) {
      margin-bottom: 10px !important;  ;

      h2 {
        text-align: unset !important;
      }
    }

    .input-range {
      position: relative;
      &__track--background {
        background: transparent;

        &:before {
          content: '';
          display: block;
          background: url("assets/UserAssets/loanRangeSlider.png") !important;
          background-repeat: no-repeat !important;
          background-size: contain !important;
          background-position: center;
          height: 25px;
          position: relative;
          top: -15px;

          @media (max-width: 580px) {
            background-size: 100% 50% !important;
          }
        }
      }
      &__track--active {
        background: none !important;
      }

      &__slider:active {
        transform: scale(1) !important;
      }

      &__slider {
        background: none !important;
        border: none !important;
        border-radius: 0 !important;
        height: 4rem !important;
        width: 4rem !important;

        &:before {
          content: "";
          display: block;
          background: url("assets/UserAssets/rangeSlider.png") !important;
          background-repeat: no-repeat !important;
          filter: drop-shadow(0px 15px 40px rgba(0, 84, 112, 0.3));
          height: 100px;
          width: 100px;
          position: relative;
          top: -60px;
          left: -20px;

          @media (max-width: 780px) {
            background: url("assets/UserAssets/mobileRangeSlider.png") !important;
            background-repeat: no-repeat !important;
            top: -55px;
          }
          @media (max-width: 580px) {
            left: -10px;
          }
        }
      }
      &__slider-container {
        .input-range__label {
          display: none;
        }
      }

      &__label {
        top: 40px;
        font-family: Mulish;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        /* identical to box height */

        text-align: right;

        color: #A4C9D6;
        position: relative;

        @media (max-width: 580px) {
          top: 20px;
        }

        &--min {
          position: absolute;
          left: 0;
          top: 35px;
        }

        &--max {
          position: absolute;
          right: 0;
          top: 35px;
        }

      }
    }
  }


  .chooseDays {
    //margin-top: 100px;
    position: relative;

      h2 {
        @media  (max-width: 580px) {
          text-align: unset !important;
        }
      }

    .input-range {
      position: relative;


      &__label {
        &--min {
          position: absolute;
          left: 0;
          top: 35px;
        }

        &--max {
          position: absolute;
          right: 0;
          top: 35px;
        }
      }
    }

    @media (max-width: 992px) {
      margin-bottom: 100px;
    }

    @media (max-width: 580px) {
      margin-bottom: 75px;
      //margin-top: 50px;
    }

    .moreDays-img {
      position: absolute;
      height: 75px;
      width: auto;
      left: 75%;
      bottom: -40px;
      transform: translateX(-20%);
      z-index: 1;

      @media (max-width: 1280px) {
        height: 55px;
        bottom: -60px;
      }

      @media (max-width: 580px) {
        bottom: -25px;
        left: 70%;
        height: 64px;
      }

      @media (max-width: 420px) {
        transform: translateX(-50%);
      }
    }

    .input-range {

      &__track--background {
        //background: url("../../../../../assets/UserAssets/newRangeSlider.png") !important;
        background: transparent;

        &:before {
          content: '';
          display: block;
          background: url("assets/UserAssets/newRangeSlider.png") !important;
          background-repeat: no-repeat !important;
          background-size: contain !important;
          background-position: center;
          height: 25px;
          position: relative;
          top: -15px;


          @media (max-width: 580px) {
            background-size: 100% 50% !important;
            background-position: right !important;
            top: -20px;
          }
        }
      }
      &__track--active {
        background: none !important;
      }

      &__slider:active {
        transform: scale(1) !important;
        z-index: 4;
      }

      &__slider {
        background: none !important;
        border: none !important;
        border-radius: 0 !important;
        height: 4rem !important;
        width: 4rem !important;


        &:before {
          content: "";
          display: block;
          background: url("assets/UserAssets/rangeSlider.png") !important;
          filter: drop-shadow(0px 15px 40px rgba(0, 84, 112, 0.3));
          background-repeat: no-repeat !important;
          height: 100px;
          width: 100px;
          position: relative;
          z-index: 4;
          top: -60px;
          left: -20px;



          @media (max-width: 780px) {
            background: url("assets/UserAssets/mobileRangeSlider.png") !important;
            background-repeat: no-repeat !important;
            top: -55px;
          }
          @media (max-width: 580px) {
            left: -10px;
          }
        }
      }
      &__slider-container {
        .input-range__label {
          display: none;
        }

      }

      &__label {
        top: 40px;
        font-family: Mulish;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        /* identical to box height */

        text-align: right;

        color: #A4C9D6;

        @media (max-width: 580px) {
          top: 20px;
        }
      }
    }
  }
}


.footer {
  position: absolute;
  bottom: 50px;
  left: 0;
  padding: 0 25px;

  @media (max-width: 1480px) {
    bottom: 25px;
  }

  @media (max-width: 1290px) {
    position: relative;
    margin-top: 50px;
    bottom: 50px;
  }

  @media (max-width: 580px) {
    margin-top: 35px;
    position: relative;
  }
}

.footerProgress {
  position: relative;
  justify-content: space-between;
  flex-wrap: wrap;



  .verifiedUl {
    margin: 0 auto;
  }

  &-list {
    display: flex;
    padding-left: 0;
    list-style: none;
    position: relative;
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 19px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;

    /* šedá */

    color: #5A7984;

    @media (max-width: 1290px) {
      width: 100%;
    }

    @media (max-width: 1290px) {
      margin: 35px 0px;
    }
  }

  &-listItem {
    margin: 0 50px;
    position: relative;

    &:after {
      content: "";
      display: block;
      background: url("assets/UserAssets/rectangle.png");
      height: 1px;
      width: 50px;
      position: absolute;
      top: 15%;
      //  transform: translateY(-50%);
      right: -75px;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }

    @media (max-width: 1475px) {
      margin: 0 35px;

      &:after {
        width: 30px;
        right: -50px;
      }
    }

    @media (max-width: 1300px) {
      width: 100%;
      order: 1;

      margin: 0 50px;

      &:after {
        width: 50px;
        right: -75px;
      }
    }
  }

  .backArrow {
    cursor: pointer;
    transition: all .2s linear;
    display: flex;
    position: relative;


    img {
      height: 20px;
    }

    &:after {
      content: "Zpět";
      display: block;
      font-style: normal;
      font-weight: 900;
      font-size: 14px;
      line-height: 18px;
      text-transform: uppercase;
      position: absolute;
      left: 0px;
      color: #5A7984;
      opacity: 0;
      width: 0;
      transition: all .2s linear;
    }
    &:hover {
      transform: scale(1.1);
      &:after {
        opacity: 1;
        width: 50px;
        left: 40px;
      }
    }

    //@media (max-width: 1290px) {
    //  display: none !important;
    //}


  }

  .btn-purple {
    font-size: 18px;
    padding: 25px 40px;
    position: relative;
    top: -25px;

    @media (max-width: 1480px) {
      font-size: 15px;
      padding: 16px 32px;
    }

    @media (max-width: 1300px) {
      font-size: 14px;
    }

    @media (max-width: 992px) {
      margin: unset;
      font-size: 14px;
      padding: 14px 32px;
      top: -15px;
    }

  }

  .btnArrow {
    &:before {
      content: "";
      background: url("assets/UserAssets/backArrow.svg") no-repeat;
      background-size: contain;
      background-position: left;
      height: 50px;
      width: 50px;
      position: absolute;
      top: -100px;
      left: 35%;
      transform: rotate(-90deg) translate(-20%, 0);
      animation: lineV 1.5s linear infinite;
    }


    @-webkit-keyframes lineV {
      from {
        top: -100px;
      }
      to {
        top: -90px;
      }
    }
    @keyframes lineV {
      from {
        top: -100px;
      }
      to {
        top: -70px;
      }
    }
  }


  @media (max-width: 1180px) {
    flex-direction: column;
    justify-content: space-between;
  }

  @media (max-width: 992px) {
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    //margin-top: -50px;
    padding: 0 15px;
    opacity: 1 !important;
  }
}

.activeStatus {
  color: #00B8F5;
  &:after {
    background: #00B8F5;
  }
}

.finishedStatus {
  color: #5A7984;
  opacity: .65;
  &:after {
    background: #00B8F5;
  }
  &:before {
    content: "";
    display: block;
    background: url("assets/UserAssets/statusIcon.png");
    background-size: cover;
    height: 34px;
    width: 34px;
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 1;
  }
}

.footerProgress-nav {

  .btn-purple {
    margin: 0;
  }

  .backArrow {
    img {
      @media (max-width: 580px) {
        height: 20px;
      }
    }
  }

}

.desktopOnly-nav {
  @media (max-width: 1290px) {
    display: none;
  }
}

.MobileOnly {
  @media (min-width: 1290px) {
    display: none !important;
  }
}

.btn-center {
  margin: auto !important;
  top: -10px;
}

.verifiedBtn {
  margin: 0 !important;
}

.hide {
  display: none !important;
}

.btnLast {
  padding: 10px 25px !important;
  font-size: 12px !important;
  line-height: 12px;
}

.btnArrow {
  &:before {
    content: "";
    background: url("assets/UserAssets/backArrow.svg") no-repeat;
    background-size: contain;
    background-position: left;
    height: 50px;
    width: 50px;
    position: absolute;
    top: -100px;
    left: 35%;
    transform: rotate(-90deg) translate(-20%, 0);
    animation: lineV 1.5s linear infinite;

    @media (max-width: 580px) {
      height: 25px;
      width: 25px;
      top: -20px;
      left: 40%;
      animation: lineVM 1.5s linear infinite;
    }
  }


  @-webkit-keyframes lineV {
    from {
      top: -100px;
    }
    to {
      top: -90px;
    }
  }
  @keyframes lineV {
    from {
      top: -100px;
    }
    to {
      top: -70px;
    }
  }

  @-webkit-keyframes lineVM {
    from {
      top: -60px;
    }
    to {
      top: -35px;
    }
  }
  @keyframes lineVM {
    from {
      top: -60px;
    }
    to {
      top: -35px;
    }
  }
}


.inputElement {
  width: 50%;
  background: #FFFFFF;
  border: 1px solid #CCDFE5;
  box-sizing: border-box;
  border-radius: 4px;
  //margin-bottom: 25px;
  height: 40px;
  padding: 5px 5px 5px 10px;
  font-family: Quicksand;
  font-weight: 500;
  transition: margin .2s linear;

  @media (max-width: 992px) {
    width: 100% !important;
  }
}

.error-border {
  &:focus {
    outline: rgba(red, .7) !important;
    border: solid 1px rgba(red, .7) !important;
  }
}

.checkForm {
   .inputElement {
     @media (max-width: 1025px) {
       width: 100% !important;
     }
   }
 }

.inputLabel {
  width: 250px;
  position: relative;
  top: 8px;
  font-family: Quicksand;
  font-style: normal;
  font-weight: normal;

}

.btn-purple-checkForm {
  position: relative;
  left: 250px;
  max-width: fit-content;
  transform: translateX(-18px);

  @media (max-width: 992px) {
    transform: none;
    left: unset;
    top: 0 !important;
    margin: auto;
    display: block;
    margin-top: 25px;
  }
}

.inputWrapper {
  position: relative;
  @media (max-width: 992px) {
    display: block !important;
  }
}

.InputElement:focus {
  outline: none !important;
  background-color: #ccc;
  border: 2px solid #00B8F5 !important;
}

input:focus,
input:active{
  outline: none !important;
  border: 2px solid #99dbf6 !important;
}

.input {
  width: 100%;
  position: relative;
}

.error {
  color: red;
  transition: all .2s ease-in-out;
  opacity: 0;
  margin-top: -50px;
  height: 50px;
  z-index: -1;
  margin-bottom: 25px;
}

.error-show {
  font-family: Mulish;
  height: auto;
  z-index: unset;
  opacity: 1;
  margin-top: 10px;
  margin-bottom: 15px;
}

.Invalid {
  border: 1px solid red;
  background-color: #FDA49A;
  margin-bottom: 50px;
}


.Question {

  &-container {
    padding-left: 35px;
    padding-right: 35px;

    @media (max-width: 1280px) {
      padding: 35px 15px;
    }

    @media (min-width: 992px) {
      margin-top: -30px
    }

    @media (max-width: 640px) {
      padding-right: 15px;
      padding-left: 15px;
    }

    @media (max-width: 580px) {
      margin-top: -30px;
    }
  }

  &-icon {
    display: block;
    margin: auto;
  }



  &-list {

    //@media (max-width: 580px) {
    //  margin-bottom: 50px;
    //  margin-top: -20px;
    //}
  }

  &-listItem {
    padding: 25px 0;
    padding-bottom: 30px;
    position: relative;

    @media (max-width: 1280px) {
      flex-direction: column;
    }

    @media (max-width: 580px) {
      padding: 10px 0;
    }
  }

  &-question {
    position: relative;

    h2 {
      font-family: Quicksand;
      font-style: normal;
      font-weight: normal;
      font-size: 22px;
      line-height: 32px;
      /* identical to box height, or 129% */


      color: #000000;

      @media (max-width: 1500px) {
        font-size: 22px;
      }

      @media (max-width: 1280px) {
        //padding-bottom: 30px;
      }

      @media (max-width: 992px) {
        font-size: 18px !important;
        line-height: 26px !important;
        text-align: left !important;
      }

      @media (max-width: 580px) {
      }
    }

    .notification {
      position: relative;
      top: -10px;
      height: 0;
      opacity: 0;
      margin-top: 0 !important;
      transition: height 1.3s linear !important;



      @media (max-width: 640px) {
        font-size: 12px;
        line-height: 18px;
      }
    }
    .showNotification {
      height: 50px !important;
      opacity: 1;
      margin-bottom: 20px !important;
      transition: height 1.3s linear !important;
    }
  }


  &-btn {
    padding: 15px 25px;
    width: 100px;
    background: #FFFFFF;
    /* šedá 2 */

    border: 1px solid #CCDFE5;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;

    input {
      opacity: 0;
      width: 0;


      &:checked {
        color:  pink;
      }
    }

    &:first-child {
      margin-right: 15px;
    }

    &__active {
      color: #fff;
      font-weight: bold;
      border: 1px solid #7D00A6;
      background: linear-gradient(90deg, #B800F5 0%, #7D00A6 100%);
      box-shadow: 0px 25px 60px rgba(112, 0, 149, 0.28);
      border-radius: 4px;
    }

    @media (max-width: 1300px) {
      padding: 8px 15px;
    }

    @media (max-width: 992px) {
      padding: 8px 32px;
      font-size: 14px;
    }

  }


  &-questionButtons {
    display: flex;
    @media (max-width: 992px) {
      //margin-top: 30px;
      //justify-content: center;
      //display: flex;
    }

    @media (max-width: 580px) {
      justify-content: unset;
      display: unset;
    }
  }
}

.notificationWrap {
  height: 0;
  opacity: 0;
  transition: all .2s linear;

  @media (max-width: 992px) {
    text-align: left;
  }

  @media (max-width: 580px) {
    text-align: left;
  }
}

.showNotif {
  height: 10px;
  opacity: 1;

  @media (max-width: 1480px) {
    margin-bottom: 10px !important;
  }
  @media (max-width: 768px) {
    height: 40px;
    opacity: 1;
  }
}

.showNotifSmall {
  height: 10px;
  opacity: 1;

  @media (max-width: 1480px) {
    margin-bottom: 10px !important;
  }

  @media (max-width: 768px) {
    height: 20px;
    opacity: 1;
    margin-bottom: 10px;
  }
}

.questions-h2 {
  font-family: Quicksand;
  font-style: normal;
  //font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  color: #000000;
  //margin-bottom: 50px;
  display: block;
  margin-bottom: 30px;

  @media (max-width: 580px) {
    font-size: 18px;
    line-height: 25px;
  }

}



/// loan-summary.scss

.loanSummary {



  &-container {

    @media (max-width: 1280px) {
      margin-top: -75px !important;
    }

    @media (max-width: 1000px) {
      margin-top: 0 !important;
    }

    @media (min-width: 992px) {
      margin-top: -50px !important;
    }

    .footerProgress {
      //top: 100px;
      justify-content: space-around;

      @media (max-width: 992px) {
        top: 10px;
        left: 30px;
      }
      @media (max-width: 768px) {
        left: 10px;
        justify-content: space-between;
      }
    }

    @media (max-width: 580px) {
      padding-top: 0px;
      padding-bottom: 30px;
      margin-top: -30px;
    }

    .loanSummaryWrap {

      .calcSummary-listItem  div{
        margin-bottom: 10px;
        display: block;
      }

      .calcSummary-listItem:last-child {
        border-bottom: none;
      }

      @media (max-width: 580px) {
        margin-top: -20px !important;
      }
    }
    .loanSummary-table {



    }
  }

  &-icon {
    width: 60%;
    position: absolute;
    bottom: 15px;
    display: block;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -40%);

    @media (max-width: 1280px) {
      position: absolute;
      top: 30%;
      left: 50%;
      transform: translate(-50%,-30%);
    }
  }



  &-info {
    width: 80%;
    margin: auto;
    margin-top: 15px;
    opacity: 1;
    transition: all .2s linear;
    p {
      font-family: Quicksand;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      /* or 175% */


      /* šedá */

      color: #5A7984;
      position: relative;
      top: 3px;
    }

    @media (max-width: 1280px) {
      margin-bottom: 0 !important;
    }

    @media (max-width: 580px) {
      width: 100%;
      //margin-top: -20px;
      //margin-bottom: 35px;
    }
  }



  &-blueBox {
    background: #00B8F5;
    box-shadow: 0px 25px 60px rgba(0, 84, 112, 0.15);
    padding: 10px;
    width: 90%;
    margin: auto;
    margin-top: -20px;
    margin-bottom: 20px;
    transform: skew(-20deg);

    .d-flex {
      transform: skew(20deg);
      justify-content: center;
    }

    img {
      height: 28px;
      width: 28px;
      margin-right: 10px;
      margin-left: 5px;

      @media (max-width: 580px) {
        height: 20px;
        width: 20px;
        margin-right: 6px;
      }
    }
    p {

      font-family: Quicksand;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 19px;
      /* identical to box height */

      text-align: center;
      color: #fff;
      position: relative;
      top: 5px;

      @media (max-width: 580px) {
        top: 1px;
      }
    }

    @media (min-width: 580px) {
      width: 50%;
    }

    @media (min-width: 992px) {
      width: 310px;
    }
  }


}
.input-range__wrapper {
  width: 60%;
  display: block;
  margin: 50px auto;
  @media (max-width: 580px) {
    width: 100%;
    margin: 30px auto !important;
  }
}
.pencil {
  height: 14px !important;
  width: 14px;
  position: relative;
  top: 4px;
  margin-right: 5px;
}

.text-blue {
  color: #00B8F5;
  font-size: 14px;
}

.pencil-box {
  //width: 200px;
  cursor: pointer;
  display: inline-block !important;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  color: #fff;
  padding: 10px 20px;
  border: 1px solid #CCDFE5;
  border-radius: 4px;
  margin-top: -20px;
  z-index: 5;
  background: linear-gradient(90deg, #B800F5 0%, #7D00A6 100%);
  box-shadow: 0px 10px 35px rgba(112, 0, 149, 0.28);

  .btn-icon-more-days {
    height: 60px;
    width: 90px;
    position: absolute;
    right: 9px;
    top: -25px;
    display: block;
  }

  //&:after {
  //  content: "";
  //  background: url("../../../../assets/UserAssets/moreDaysToday.png");
  //  background-size: contain;
  //  background-repeat: no-repeat;
  //  height: 60px;
  //  width: 80px;
  //  position: absolute;
  //  right: 9px;
  //  top: -13px;
  //  display: block;
  //}
}

.plus {
  font-size: 18px;
}

.text-underline {
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;

}

.summary-addDays {
  .btn-main {
    display: inline-block;
    margin: auto;

    @media (max-width: 992px) {
      display: block;
    }

    @media (max-width: 580px) {
      width: 200px;
      display: block;
    }
  }

  b {
    font-size: 20px;
  }

  .Keys {
    height: 300px !important;
  }

  .btn-purple {
    @media (max-width: 992px) {
      top: 0;
      display: block;
      margin: 0;
    }

    @media (max-width: 580px) {
      padding: 10px 15px;
      top: -5px;
    }

    .mobileText {
      display: none;
    }

    @media (max-width: 580px) {
      .mainText {
        display: none;
      }

      .mobileText {
        display: block;
      }
    }
  }

  .mainText {
    font-weight: bold;
  }

  @media (max-width: 580px) {
    padding-top: 30px;
  }

}

.addDays-list {
  width: 80%;
  display: block;
  margin: 50px auto 40px auto;

  @media (max-width: 580px) {
    width: 100%;
  }
}

.textCap {
  font-size: 18px !important;
  line-height: 23px;

  @media (max-width: 580px) {
    font-size: 15px !important;

  }
}

.none {
  opacity: 0;
}

.Keys {
  //bottom: 50px !important;
}


/// input-range.scss

.input-range {
  &__wrapper {
    margin-bottom: 85px;
  }
  &__track--background {
    background: transparent;

    &:before {
      content: '';
      display: block;
      background: url("assets/UserAssets/additionalDaysSlider.png") !important;
      background-repeat: no-repeat !important;
      background-size: contain !important;
      background-position: center;
      height: 25px;
      position: relative;
      top: -15px;

      @media (max-width: 580px) {
        background-size: 100% 50% !important;
      }
    }
  }
  &__track--active {
    background: none !important;
  }

  &__slider:active {
    transform: scale(1) !important;
  }

  &__slider {
    background: none !important;
    border: none !important;
    border-radius: 0 !important;
    height: 4rem !important;
    width: 4rem !important;

    &:before {
      content: "";
      display: block;
      background: url("assets/UserAssets/mobileRangeSlider.png") !important;
      background-repeat: no-repeat !important;
      filter: drop-shadow(0px 15px 40px rgba(0, 84, 112, 0.3));
      height: 100px;
      width: 100px;
      position: relative;
      top: -55px;
      left: -20px;

      @media (max-width: 780px) {
        background: url("assets/UserAssets/mobileRangeSlider.png") !important;
        background-repeat: no-repeat !important;
        top: -55px;
      }
      @media (max-width: 580px) {
        left: -10px;
      }
    }
  }
  &__slider-container {
    .input-range__label {
      display: none;
    }
  }

  &__label {
    top: 40px;
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    text-align: right;

    color: #A4C9D6;

    @media (max-width: 580px) {
      top: 20px;
    }
  }
}


.btnsWrap {
  width: 50%;
  display: block;
  margin: 0 auto;
  .mainText {
    display: block !important;
  }

  .btn-whiteSetup {
    @media (max-width: 580px) {
      width: 50%;
      margin: auto !important;
      margin-bottom: 20px !important;
    }

    @media (max-width: 380px) {
      width: 80%;
      padding: 10px 20px;
    }
  }

  .btn-purple {
    @media (max-width: 580px) {
      width: 50%;
      margin: auto !important;
    }

    @media (max-width: 380px) {
      width: 80%;
    }
  }

  @media (max-width: 580px) {
    flex-direction: column;
    width: 100%;
  }
}

.d-wrap {
  display: inline-block;
  padding: 8px 15px;
  margin: 4px auto;
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  /* identical to box height, or 108% */

  text-align: center;
  color: black;

  b {
    font-family: Quicksand;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 28px;
  }

}

.summary {
  .Modal {
    @media (max-width: 992px) {
      width: 90% !important;
      padding: 65px 30px;
    }
  }

  .checkForm {

    @media (max-width: 580px) {
      padding: 0 10px;
    }
  }

  &-textBox {
    @media (max-width: 992px) {
      padding-top: 50px;
      padding-bottom: 50px;
    }
  }

  &-box {
    background: #FFFFFF;
    box-shadow: 0px 25px 60px rgba(0, 84, 112, 0.15);
    border-radius: 10px;
    position: relative;
    padding: 45px;
    text-align: center;
    top: -35px;

    @media (max-width: 992px) {
      width: 80%;
      margin: auto;
    }

    @media (max-width: 580px) {
      width: 95%;
    }

  }

  &-optionsCircles {
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
    height: 80px !important;
    width: auto;

  }

  &-userName {
    font-family: Quicksand;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 40px;
    /* identical to box height, or 111% */

    text-align: center;
    margin-top: 45px;

    @media (max-width: 580px) {
      font-size: 26px;
      line-height: 30px;
    }
  }

  &-greyText {
    font-family: Quicksand;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 25px;
    /* identical to box height */

    text-align: center;

    /* šedá */

    color: #5A7984;

    @media (max-width: 580px) {
      font-size: 18px;
    }
  }

  &-address {
    margin-top: 50px;
    p {
      margin-bottom: 0 !important;
    }

    @media (max-width: 580px) {
      margin-top: 30px;
    }
  }

  &-loginComponent {
    margin-top: 45px;
  }

  &-blueText {
    font-family: Quicksand;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    /* identical to box height */

    text-align: center;

    /* modrá */

    color: #00B8F5;
  }

  &-btn-purple {
    @media (max-width: 992px) {
      top: 0;
    }
  }
}

.actionButtons {
  width: 100%;
  text-align: center;
  margin-top:25px;

  .btn-purple {
    @media (max-width: 992px) {
      top: unset;
      margin-top: 15px;
    }
  }

  .btn-white {
    font-weight: bold;
    @media (max-width: 992px) {
      margin: auto;
    }
  }

  @media (max-width: 580px) {
    margin-top: 0;
  }
}

.summary-login {

  .inputLabel {
    text-align: left;
    width: 200px;
    padding-left: 30px;

    @media (max-width: 768px) {
      padding-left: 0;
      width: 100%;
    }
  }

  .inputElement {
    width: 50%;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .btn-purple-checkForm {
    left: unset;
    transform: translateX(0);
    display: block;
    margin: auto;
    margin-top: 50px;
  }

  .forgotPassword {
    position: relative;
    top: -15px;
    left: -22px;
    font-family: Quicksand;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    text-align: center;
    text-decoration-line: underline;

    /* modrá */

    color: #00B8F5;
    cursor: pointer;

    @media (max-width: 992px) {
      left: 60px;
    }
    @media (max-width: 768px) {
      left: unset;
    }
  }

  .Keys {
    position: absolute;
    left: 0;
    height: 200px;
    bottom: -5px;

    @media (max-width: 768px) {
      display: none;
    }
  }

  &-modalHeading {
    font-family: Quicksand;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 40px;
    /* identical to box height, or 143% */

    text-align: center;

    color: #000000;

    @media (max-width: 580px) {
      font-size: 22px;
      line-height: 36px;
    }
  }
}

.summary-forgotPassword {
  .inputLabel {
    margin-right: 15px;
    margin-left: 35px;
  }

  .btn-purple-checkForm {
    margin-top: 20px;
  }
}

.Modal {
  overflow: hidden;
}

.closeModal {
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
  height: 20px !important;
}

/// spending-report.scss
.SpendingReport {

  &-container {
    position: relative;
    left: -50px;
    padding-top: 20px;

    @media (max-width: 1280px) {
      left: 0;
    }

    @media (max-width: 580px) {
      margin-top: -40px;
    }

    .show {
      opacity: 1;
      position: relative;
      visibility: visible;
    }
  }


  &-icon {
    top: 40%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -40%);

    @media (max-width: 1280px) {
      width: 350px;
    }
  }

  &-list {
    list-style: none;
    //margin-bottom: 100px;

    @media (max-width: 1480px) {
      margin-bottom: 20px;
    }

    @media (max-width: 780px) {
      margin-bottom: 40px;
    }

    @media (max-width: 580px) {
      margin-bottom: 40px;
      margin-top: -20px;
    }
  }

  &-listItem {
    padding: 40px 0;

    @media (max-width: 1280px) {
      padding: 20px 0;
    }

    @media (min-width: 992px) {
      padding: 30px 0;
      padding-bottom: 25px;
    }


    h2 {
      font-family: Quicksand;
      font-style: normal;
      font-weight: normal;
      font-size: 25px;
      line-height: 32px;
      /* identical to box height, or 129% */


      color: #000000;

      @media (max-width: 1280px) {
        font-size: 20px;
        line-height: 26px;
      }

      @media (max-width: 580px) {
        font-size: 16px !important;
        margin-top: 0 !important;
      }
    }

    input[type=text] {
      width: 100%;
      padding: 5px;
      padding-left: 15px;
      background: #FFFFFF;
      /* šedá 2 */
      height: 50px;
      border: 1px solid #CCDFE5;
      box-sizing: border-box;
      border-radius: 4px;

      @media (max-width: 580px) {
        height: 40px;
      }
    }

    .currencyWrap {
      position: relative;

    }

    .bills {
      margin-top: 10px;
      width: 70%;
      margin-left: auto;

      @media (max-width: 1280px) {
        width: 40%;
      }

      @media (max-width: 580px) {
        width: 50%;
      }
    }

    .billsQuestion-wrap {
      display: flex;
      padding-left: 10px;
      margin-top: 20px;

      @media (max-width: 1280px) {
        margin-top: 10px;
      }

      @media (max-width: 580px) {
        margin-top: 0;
      }
    }

    .desktopBillsQuestion-wrap {
      @media(max-width: 1280px) {
        display: none;
      }
    }

    .blueArrow {
      height: 30px;
      width: auto;
    }

    .billsQuestion {
      font-family: Quicksand;
      font-style: normal;
      //font-weight: 300;
      font-size: 20px;
      line-height: 36px;
      color: #000000;
      position: relative;
      top: 4px;
      left: 10px;

      @media (max-width: 580px) {
        font-size: 16px;
        line-height: 24px;
        top: 10px;
      }
    }

    .mobileBills-wrap {
      display: flex;
      .billsQuestion-wrap {
        display: none;
        @media (max-width: 1280px) {
          display: flex;
        }
      }
    }

    .currencyPlaceholder {
      position: absolute;
      right: 15px;
      top: 12px;
      font-size: 18px;

      @media (max-width: 1280px) {
        top: 12px;
      }

      @media (max-width: 580px) {
        top: 10px;
      }
    }

    @media (max-width: 580px) {
      padding: 20px 0;
    }
  }

  &-result {
    margin-bottom: 50px;
    list-style: none;
    background: #FFFFFF;
    box-shadow: 0px 25px 60px rgba(0, 84, 112, 0.15);
    border-radius: 10px;
    padding: 35px 25px;

    h2 {
      font-family: Quicksand;
      font-style: normal;
      font-weight: 600;
      font-size: 26px;
      line-height: 33px;
      /* identical to box height, or 129% */


      color: #000000;

      @media (max-width: 1280px) {
        font-size: 20px;
        line-height: 26px;
      }

      @media (max-width: 580px) {
        font-size: 16px;
      }

      &:first-child {
        @media (max-width: 1280px) {
          font-size: 18px;
          line-height: 24px;
          flex: 0 0 70%;
        }

        @media (max-width: 580px) {
          flex: 0 0 100%;
        }
      }
    }
    @media (max-width: 580px) {
      flex-direction: column;
      padding: 25px 15px 15px 15px;
      text-align: center;
      margin-bottom: 20px;
    }
  }

}


.spendingReportResultHeading {
  @media (max-width: 580px) {
    font-size: 16px !important;
    font-weight: normal !important;
  }
}

.spendingReportResultAmount {
  @media (max-width: 580px) {
    font-size: 22px !important;
  }
}


/// purpose.scss
.Purpose {

  &-container {
    max-width: 1440px;
    margin-top: -20px;
    padding-top: 0px;
    position: relative;
    left: -5%;

    @media (min-width: 1800px) {
      left: -10%;
    }

    @media (max-width: 1600px) {
      left: 0;
    }

    @media (max-width: 992px) {
      margin-top: 0px;
    }

    @media (max-width: 580px) {
      padding-top: 0 !important;
      margin-top: -30px !important;
    }

    .Purpose-icon {
      position: absolute;
      top: 5%;
    }
  }

  &-purposeButtons {
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    @media (max-width: 580px) {
      margin-top: 0;
    }
  }

  &-btn {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    font-family: Quicksand;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 25px;
    color: #000000;
    width: 50%;
    margin: 7px 0;

    input[type=radio] {
      visibility: hidden;
    }

    &:before {
      display: block;
      content: '';
      height: 25px;
      width: 25px;
      border-radius: 50%;
      background: #FFFFFF;
      border: 1px solid #CCDFE5;

      @media (max-width: 580px) {
        height: 30px;
        width: 30px;
      }
    }

    &__active:before {
      border:1px solid #B800F5;
    }

    &__active:after {
      position: absolute;
      height: 15.1px;
      width: 15.1px;
      content: '';
      left: 5.2px;
      top: 5.4px;
      //left: 1.27%;
      //top: 19%;
      border-radius: 50%;
      background: #B800F5;

      @media (max-width: 580px) {
        height: 10px;
        width: 10px;
        top: 10px;
        left: 10px;
      }
    }

    .labelText {
      position: relative;
      //top: 12px;
      height: 30px;

      @media (max-width: 580px) {
        font-size: 16px;
        top: 4px;
      }
    }

    @media (max-width: 780px) {
      width: 100%;
    }
  }

  &-list {
    list-style: none;
    //margin-bottom: 100px;

    //.Question-listItem {
    //
    //  @media (max-width: 1480px) {
    //    padding: 30px 0px;
    //  }
    //
    //  @media (max-width: 780px) {
    //    padding: 20px 10px;
    //  }
    //}


    .Question-listItem:first-child {
      //padding-bottom: 70px;

      @media (max-width: 1480px) {
        //padding-bottom: 30px;
      }

      @media (max-width: 780px) {
        //padding-bottom: 20px;
      }

      @media (max-width: 580px) {
        //padding-bottom: 20px;
        //margin-top: -40px;
      }
    }

    @media (max-width: 1280px) {
      margin-bottom: 20px;
    }

    @media (max-width: 780px) {
      margin-bottom: 40px;
    }

    @media (max-width: 580px) {
      margin-bottom: 30px;
      margin-top: 15px !important;
    }
  }



}

.otherInputWrap {
  display: block;
  position: relative;
  z-index: 10;
  top: 15px;
  left: 55px;
  opacity: 0;
  height: 0;
  transition: all .2s linear;

  .googleSuggestions {
    top: -25px;
    position: relative;
  }

  input {
    background: #FFFFFF;
    border: 1px solid #CCDFE5;
    padding: 5px;
    box-sizing: border-box;
    border-radius: 4px;
    width: 80%;
    font-size: 16px;
    position: relative;
    z-index: 10;


    @media (max-width: 780px) {
      width: 80%;
    }

    @media (max-width: 580px) {
      width: 80%;
      font-size: 14px;
    }
  }

  @media (max-width: 580px) {
    left: 40px;
  }
}

.show {
  opacity: 1;
  height: 50px;
}

.otherEarnings {
  @media (max-width: 580px) {
    flex-direction: column;
  }

}
.show-error {
  display: block;
  @media(max-width: 480px) {
    font-size: 14px;
  }
}

.height100 {
  height: 80px;
  @media (max-width: 480px) {
    height: 80px;
  }
}


/// verification.scss
.checkPerson {
  max-width: 1300px;
  margin-left: 5%;
  transition: all .2s linear;
  margin-top: 50px;

  @media (min-width: 2000px) {
    margin: auto;
  }

  &-textBox {
    position: relative;
    //left: -100px;

    h2 {
      font-family: Quicksand;
      font-style: normal;
      font-weight: normal;
      font-size: 22px;
      line-height: 34px;
      transition: all .2s linear;

      @media (max-width: 992px) {
        font-size: 24px;
        line-height: 38px;
        text-align: center;
        margin-top: 25px;
        //padding: 0 20px;
      }

      @media (max-width: 580px) {
        font-size: 20px;
        line-height: 28px;
      }
    }

    @media (max-width: 992px) {
      left: unset;
    }
  }

  .checkForm {
    margin-top: 75px;
    transition: all .2s linear;

    @media (max-width: 992px) {
      margin-top: 25px;
      padding: 0px 35px;
    }

  }

  @media (max-width: 1280px) {
    padding-top: 50px;
  }

  @media (max-width: 992px) {
    padding-top: 0;
    padding-bottom: 0;
    margin: 0
  }
}

.Modal-email {
  font-weight: normal !important;
}

.intro {
  @media (max-width: 1024px) and (min-width: 992px) {
    justify-content: space-around !important;
  }
}

//
//
//
//
//
//
//
//.DrawerToggler {
//  width: 40px;
//  height: 52px;
//  display: flex;
//  flex-flow: column;
//  justify-content: space-around;
//  align-items: center;
//  padding: 10px 0;
//  box-sizing: border-box;
//  cursor: pointer;
//  position: relative;
//  z-index: 110;
//
//  div {
//    width: 90%;
//    position: relative;
//    height: 3px;
//    background: #00B8F5;
//    transition: all .07s linear;
//  }
//
//
//  @media (min-width: 992px) {
//    display: none;
//  }
//}
//
//.DrawerToggler.open {
//
//
//  div:nth-child(1) {
//    top: 16px;
//    width: 0;
//    opacity: 0;
//
//  }
//
//  div:nth-child(2) {
//    -webkit-transform: rotate(45deg);
//    -moz-transform: rotate(45deg);
//    -o-transform: rotate(45deg);
//    transform: rotate(45deg);
//  }
//
//  div:nth-child(3) {
//    top: -11px;
//    -webkit-transform: rotate(-45deg);
//    -moz-transform: rotate(-45deg);
//    -o-transform: rotate(-45deg);
//    transform: rotate(-45deg);
//  }
//
//  //div:nth-child(4) {
//  //  top: 18px;
//  //  width: 0%;
//  //  left: 50%;
//  //}
//}
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//.SideDrawer {
//  position: fixed;
//  width: 100%;
//  //max-width: 70%;
//  height: 100%;
//  left: 0;
//  top: 0;
//  z-index: 200;
//  padding-top: 90px;
//  //background: radial-gradient(116.48% 209.06% at 80.47% 81.35%, #DFF7FF 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF;
//  background-color: #fff;
//  //padding: 32px 16px;
//  box-sizing: border-box;
//  transition: transform 0.3s ease-out;
//}
//
//
//
//@media (min-width: 992px) {
//  .SideDrawer {
//    display: none;
//  }
//}
//
//.Open {
//  transform: translateX(0);
//}
//
//.Close {
//  transform: translateX(-100%);
//}
//
//
//@media (max-width: 992px) {
//  .li-wrap {
//    width: 100%;
//    border-bottom: 1px solid rgba(128, 128, 128, .14);
//    border-top: 1px solid rgba(128, 128, 128, .14);
//    //color: gray;
//  }
//}






/// btn-main.scss
.btn-main {
  padding: 16px 32px;
  background: linear-gradient(0deg, #FFFFFF, #FFFFFF);
  border: 1px solid #CCDFE5;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  margin: auto 10px;
  transition: all .2s ease-in-out;

  &:hover {
    box-shadow: 0px 10px 35px rgba(158, 158, 158, 0.28);

  }

  @media (max-width: 1180px) {
    padding: 16px 22px;
  }

  @media (max-width: 1024px) {

  }

  @media (max-width: 992px) {
    margin-bottom: 15px;
    display: block;
  }
}

.smallText {
  display: block;
  text-transform: none;
  font-size: 12px;
  font-weight: normal;
}

.hoverNone:hover {
  box-shadow: none !important;
}



// new range slider

.MuiSlider-rail,
.MuiSlider-track {
  height: 5px !important;
  border-radius: 15px !important;
}

.MuiSlider-rail {
  background-color: #D5EAF1 !important;
  opacity: 1 !important;
}

.chooseDays {

  .MuiSlider-rail::after {
    content: "";
    position: absolute;
    height: 5px;
    width: 50%;
    right: 0;
    background-color: #FDC93A;
    transition: all .2s linear;
    opacity: 1;
    //z-index: 11;

  }

  span[data-index="4"],
  span[data-index="5"],
  span[data-index="6"],
  span[data-index="7"],
  span[data-index="8"] {
    background-color: #FDC93A !important;
  }
}

//.MuiSlider-root:hover {
//  .MuiSlider-rail::after {
//    //display: block;
//    opacity: 1;
//  }
//}
.MuiSlider-track {
  background: linear-gradient(90deg, #B800F5 0%, #7D00A6 100%) !important;
  opacity: .8 !important;
}

.MuiSlider-mark {
  height: 20px !important;
  top: 5px !important;
  background-color: #C3DCE4 !important;

  @media (max-width: 1366px) {
    top: 12px !important;
  }

}

.MuiSlider-thumb::after  {
  width: 48px;
  height: 48px;
  display: flex;
  transform: rotate(-225deg) !important;
  align-items: center;
  border-radius: 50% 50% 50% 0 !important;
  justify-content: center;
  background: linear-gradient(90deg, #B800F5 0%, #7D00A6 100%) !important;

  @media (max-width: 580px) {
    //top: 20px !important;
    height: 32px;
    width: 32px;
    bottom: 0 !important;
    top: -10px !important;
  }
}

.MuiSlider-thumb::before {
  content: "";
  background: url("assets/UserAssets/markersPoints.png") no-repeat;
  background-size: contain;
  height: 20px;
  left: 2px;
  top: -2px;
  width: 15px;
  display: block;
  position: absolute;
  z-index: 10;

  @media (max-width: 580px) {
    //top: 20px !important;
    height: 16px;
    width: 16px;
    left: -4px;
    top: -3px;
  }
}

.MuiSlider-markActive {
  background: linear-gradient(90deg, #B800F5 0%, #7D00A6 100%) !important;
}

#input-slider {
  color: #A4C9D6 !important;
  font-weight: 600;
  font-family: Quicksand;
  margin-top: 10px;
}

.MuiSlider-root {

}

.pointer-events-none {
  pointer-events: none !important;
}

.opacity-0 {opacity: 0 !important;}
.opacity-1 {opacity: 1 !important;}


.pushUp {
  transition: all .2s ease-in-out;
  margin-top: -50px;
}

.pushDown {
  transition: all .2s ease-in-out;

  margin-top: 0;
}



/// modal.scss
.Modal {
  position: absolute;
  z-index: 1500;
  background: #FFFFFF;
  box-shadow: 0px 50px 120px rgba(0, 84, 112, 0.25);
  border-radius: 10px;
  //top: 10%;
  width: 95%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -150vh);
  padding: 35px;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
  opacity: 0;

  .notification {
    z-index: -1;
    position: relative;
  }

  .showNotification {
    height: 40px;
    margin-top: -40px;
    margin-bottom: 25px;
    transition: all .2s linear;
    z-index: 2;
  }

  .text-small {
    font-family: Quicksand;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    /* or 156% */
    text-align: center;

    /* šedá */

    color: #5A7984;
    margin-top: 25px;
    b {
      color: #000;
    }


  }

  &-heading {
    font-family: Quicksand;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 36px;
    /* or 143% */

    text-align: center;

    /* černá */

    color: #000000;

    @media (max-width: 580px) {
      font-size: 18px;
      line-height: 30px;
    }
  }


  &-text {
    // font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    /* identical to box height, or 160% */

    text-align: center;

    /* šedá */

    color: #5A7984;

    margin-top: 45px;

    @media (max-width: 580px) {
      margin-top: 25px;
      font-size: 16px;
      line-height: 28px;
    }
  }

  &-phone {
    // font-family: Muli;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 32px;
    /* identical to box height, or 100% */

    text-align: center;

    /* černá */

    color: #000000;
    transition: all .2s linear;

    &:hover {
      color: #00B8F5;
    }

    @media (max-width: 580px) {
      font-size: 24px;
      line-height: 26px;
    }
  }

  &-email {
    //font-family: Muli;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 32px;
    /* identical to box height, or 100% */

    text-align: center;
    text-decoration-line: underline;

    /* modrá */

    color: #00B8F5;
    transition: all .2s linear;

    &:hover {
      color: #00B8F5;
    }

    @media (max-width: 580px) {
      font-size: 24px;
      line-height: 26px;
    }
  }

  &-btn {
    // font-family: Muli;
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    text-transform: uppercase;

    /* bílá */

    color: #FFFFFF;
  }

  @media (min-width: 800px) {
    position: fixed;
    width: 800px !important;
    //top: 20% !important;
    //left: 50%;
    //transform: translateX(-50%) !important;
    padding: 65px;
    text-align: center;
  }

  @media (max-width: 480px) {
    padding: 20px;
  }

  .Modal-text {
    font-family: Quicksand;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 36px;
    /* or 143% */

    text-align: center;

    /* černá */

    color: #000000;

    @media (max-width: 580px) {
      font-size: 18px;
      line-height: 30px;
    }

    a {
      font-size: 22px;
      line-height: 36px;
      @media (max-width: 580px) {
        font-size: 18px;
        line-height: 30px;
      }
    }
  }
  .text-purple {
    color: #B800F5 !important;
  }
}
.showModal {
  transform: translate(-50%, -50%);
  opacity: 1;
  @media (max-width: 480px) {
    transform: translate(-50%, -55%);
  }
}


#text-purple {
  color: #B800F5 !important;
}

.Backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1010;
  left: 0;
  top: 0;
  background: #FFFFFF;
  opacity: 0.85;
  filter: blur(4px);
}





/// contract.scss
.Contract {

  &-container {
    max-width: 1700px;
    position: relative;
    left: -50px;
    margin-top: 15px;

    .Question-icon {
      top: 30px;
      position: relative;
    }


    @media (max-width: 1340px) {
      left: 0;
    }

  }

  &-list {
    margin-bottom: 120px;
    list-style: none;

    .smallText {
      font-family: Quicksand;
      font-style: italic;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #5A7984;
      padding-left: 80px;
      margin-top: -10px;

      @media (max-width: 580px) {
        padding-left: 40px;
        margin-top: -5px;
      }

    }

    .input{
      position: relative;
      z-index: 10;
      width: 40px;
      height: 40px;
      left: 2px;
      opacity: 0;
      cursor: pointer;
      margin-right: 30px;
      flex: 5%;

      @media (max-width: 580px) {
        flex: 7%;
        margin-right: 20px;
      }
    }


    .Question-listItem {
      position: relative;
      padding: 35px 0;

      h2 {
        font-family: Quicksand;
        font-style: normal;
        font-weight: normal;
        font-size: 28px;
        line-height: 36px;
        /* identical to box height, or 129% */

        flex: 100%;
        color: #000000;

        a {
          text-decoration: underline;
          color: #00B8F5;
        }

        @media (max-width: 580px) {
          font-size: 16px;
          line-height: 26px;
        }
      }

      .checkBox-h2 {
        @media (max-width: 580px) {
          margin-top: 0 !important;
          text-align: left;
        }
      }


      &:before {
        display: block;
        position: absolute;
        content: '';
        height: 45px;
        width: 45px;
        top: 32px;
        background: #FFFFFF;
        border: 1px solid #CCDFE5;
        cursor: pointer;

        @media (max-width: 580px) {
          height: 30px;
          width: 30px;
          top: 20px;
        }
      }

      &__active:before {
        border:1px solid #B800F5;
      }

      &__active:after {
        position: absolute;
        height: 30px;
        width: 30px;
        content: '';
        left: 8px;
        top: 45px;
        background: url("assets/UserAssets/trueTriangle.svg");
        background-size: contain;
        background-repeat: no-repeat;
        cursor: pointer;

        @media (max-width: 580px) {
          height: 20px;
          width: 20px;
          top: 28px;
          left: 5px;
        }
      }

      .repiLogo {
        padding-left: 50px;
        img {
          position: relative;
          top: -20px;
          height: 90px;
          width: 210px !important;
          @media (max-width: 580px) {
            //margin: auto;
            height: 60px;
            width: auto;
            margin-bottom: 10px;
          }
        }

        .blueText {
          position: relative;
          top: 15px;
          font-family: Quicksand;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 20px;
          /* identical to box height */

          text-align: center;
          text-decoration-line: underline;

          /* modrá */

          color: #00B8F5;


          @media (max-width: 440px) {
            top: -40px;
            text-align: left;
            padding-left: 25px;
          }
        }

        @media (max-width: 580px) {
          padding-left: 15px;
          height: 60px;
        }

        @media (max-width: 440px) {
          flex-direction: column;
        }
      }

      @media (max-width: 580px) {
        padding: 15px 0;
        &:last-child {
          padding-bottom: 40px;
        }
      }
    }

    @media (max-width: 1480px) {
      margin-bottom: 20px;
    }

    @media (max-width: 780px) {
      margin-bottom: 40px;
    }

    @media (max-width: 580px) {
      margin-bottom: 40px;
      margin-top: -20px;
    }
  }

  .Modal {
    .Modal-text {
      font-family: Quicksand;
      font-style: normal;
      font-weight: normal;
      font-size: 22px;
      line-height: 36px;
      /* or 143% */

      text-align: center;

      /* černá */

      color: #000000;

      @media (max-width: 580px) {
        font-size: 18px;
        line-height: 30px;
      }
    }
  }
}


.Address-container {
  margin-top: 0 ;
  padding-top: 50px;

  .Purpose-btn {
    width: 93%;
  }
  @media (max-width: 780px) {
    margin-top: -30px;
  }

  .otherInputWrap {
    .error {
      @media (max-width: 992px) {
        font-size: 16px;
      }
    }
  }
}

.empty-inbox {
  position: absolute;
  height: 250px !important;
  top: -105px;
  left: -115px;
  z-index: 0;
}
.addressBox {
  padding-top: 50px;

  @media (max-width: 780px) {
    margin-bottom: 25px;
  }

  @media (max-width: 580px) {
    padding-top: 10px;
  }

  .calcSummary-list {
    list-style: none;
  }

  .calcSummary {
    @media (max-width: 580px) {
      padding-bottom: 5px;
      margin-top: -10px !important;
      margin-bottom: 15px;
    }
  }

  .calcSummary-listItem1 {
    position: relative;
    padding-left: 60px;
    padding-top: 35px;
    padding-bottom: 20px;
    height: 65px;
    border-bottom: none;

    &:before {
      content: "";
      height: 42px;
      width: 42px;
      position: absolute;
      top: 25px;
      left: 0;
      padding: 10px;
      display: block;
      text-align: center;
      vertical-align: center;
      border-radius: 50%;
      background: url("assets/UserAssets/true.png");
      background-size: cover;
      color: #fff;
      border-color: #00B8F5;
      transition: all .2s;

      @media (max-width: 580px) {
        height: 32px;
        width: 32px;
        top: 15px;
      }
    }

    @media (max-width: 580px) {
      padding-left: 40px;
      padding-top: 20px;
    }
  }

  .calcSummary-blue {
    @media (max-width: 580px) {
      font-size: 16px;
    }
  }

  .text-bold {
    text-align: left;
  }

  @media (max-width: 580px) {
    margin-bottom: 30px;
  }
}

.address {

  .Question-listItem {
    padding-bottom: 40px !important;

    @media (max-width: 580px) {
      padding-bottom: 20px !important;
    }
  }

  &-info {
    margin: 0;
  }

  .Purpose-list {
    margin-bottom: 20px;
  }

  @media (max-width: 580px) {
    margin-bottom: 20px;
  }

}

.googleSuggestions {
  position: relative;
  z-index: 100;

  & > * {
    @media (max-width: 580px) {
      font-size: 16px !important;
    }
  }
}




/// registration.sccs

.Registration {
  margin: 0;
  max-width: 1400px;
  padding-top: 50px;

  @media (max-width: 780px) {
    margin-top: -30px !important;
  }

  @media (max-width: 580px) {
    padding-top: 0 !important;
    margin-top: -30px !important;
  }

  .Question-list {
    list-style: none;

    .main-heading {
      font-family: Quicksand;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 36px;
      /* or 143% */


      color: #000000;
      margin-bottom: 30px;

      @media (max-width: 580px) {
        font-size: 20px;
        line-height: 28px;
        text-align: center;
      }
    }

  }

  .Question-listItem {
    border-top: 1px solid #CCDFE5;
    h2 {
      font-family: Quicksand;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 36px;
      /* identical to box height, or 180% */


      color: #000000;

      @media (max-width: 580px) {
        font-size: 16px !important;
        line-height: 24px;
      }
    }

    input[type=password],
    input[type=email],
    input[type=text]{
      width: 100%;
      max-width: 350px;
      background: #FFFFFF;
      /* šedá 2 */
      padding: 5px 5px 5px 15px;
      border: 1px solid #CCDFE5;
      box-sizing: border-box;
      border-radius: 4px;
      height: 45px;
      font-size: 16px;

      @media (max-width: 580px) {
        max-width: 100%;
        height: 40px;
      }
    }

    input[type=password] {
      font-size: 32px;
      @media (max-width: 580px) {
        font-size: 16px;
        order: 3;
      }
    }

    .d-flex {
      @media (max-width: 580px) {
        flex-direction: column;
      }
    }

    @media (max-width: 780px) {
      padding-bottom: 40px;
    }
  }

  @media (max-width: 580px) {
    margin-top: -30px;
  }
}

.errPass {
  border-color: red !important;

  &:active,
  &:focus{
    border-color: red !important;
  }
}

.err {
  display: block;
  bottom: -10px;
  left: 0;
  top: unset;

  @media (max-width: 780px) {
    width: 50%;
    margin-bottom: 10px;
  }

  @media (max-width: 580px) {
    position: relative;
    width: 100%;
  }
}

.showErr {
  display: block;
}

.passinfo {
  position: absolute;
  bottom: -12px;

  @media (max-width: 1180px) {
    width: 290px;
    bottom: -20px;
    line-height: 18px;
  }

  @media (max-width: 992px) {
    width: 100%;
    bottom: -10px;
  }

  @media (max-width: 768px) {
    width: 290px;
    bottom: -20px;
  }

  @media (max-width: 580px) {
    width: 100%;
    position: relative;
    order: 2;
    top: -10px;
  }
}

.emailinfo {
  position: absolute;
  bottom: -40px;

  @media (max-width: 580px) {
    position: relative;
    bottom: unset;
    top: 10px;
    display: block;
    height: 35px;
  }
}

.phoneinfo {
  @media (max-width: 580px) {
    margin-bottom: 10px;
  }
}

.registration {

  &-main-heading {
    @media (max-width: 580px) {
      margin-top: 0 !important;
      margin-bottom: 30px !important;
      font-size: 18px !important;
      line-height: 24px;
    }
  }

  .input {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  h2 {
    color: black;
    @media (max-width: 992px) {
      text-align: left;
    }

    @media (max-width: 580px) {
      font-size: 16px;
      line-height: 24px;
      margin-top: 0 !important;
    }
  }

  //input {
  //  //min-width: 400px;
  //  max-width: 400px;
  //}
}


.trueIcon {
  width: 38px;
  height: 38px !important;
  display: block;
  margin-right: 15px;
  margin-top: 5px;
}

.address__input {
  width: 75%;

  @media (max-width: 992px) {
    width: 100%;
  }
}


.ViewContainer {
  height: 75%;

  @media (max-width: 992px) {
    height: 100%;
    align-content: flex-start !important;
  }

  @media (max-width: 580px) {
    height: auto !important;
  }

}

.addressOrder-1 {
  @media (max-width: 992px) {
    order: 1
  }
}
.addressOrder-2 {
  @media (min-width: 992px) {
    margin-right: 100px;
  }

  @media (max-width: 992px) {
    order: 2
  }
}


#text-white {
  color: #fff !important;
}

.invisible {
  @media (max-width: 580px) {
    display: none !important;
  }
}

.spaceMobile {
  @media (max-width: 580px) {
    justify-content: center;
  }
}

.mobile-progress {
  display: none;

  @media (max-width: 992px) {
    display: flex;
  }
}

.mobileProgress {
  padding-left: 10px;

  &::-webkit-scrollbar {
    display: none;
  }

  &-list {
    padding-left: 0;
    list-style: none;
    display: flex;
  }

  &-listItem1 {
    opacity: 1;
    display: flex;
    color: transparent;
    margin-right: 10px;
    transition: all .2s linear;

    &:before {
      content: "1";
      height: 42px;
      width: 42px;
      position: relative;
      top: -10px;
      margin-right: 5px;
      padding: 8px;
      display: block;
      text-align: center;
      vertical-align: center;
      border-radius: 50%;
      color:  #5A7984;
      background-color: #fff;
      border: 1px solid #CCDFE5;
      transition: all .2s linear;
    }

    &:after {
      content: "Žádost";
      font-size: 16px;
      display: none;
      transition: all .2s linear;
    }
  }

  &-listItem2 {
    opacity: 1;
    display: flex;
    color: transparent;
    transition: all .2s linear;


    &:before {
      content: "2";
      height: 42px;
      width: 42px;
      position: relative;
      top: -10px;
      margin-right: 5px;
      padding: 8px;
      display: block;
      text-align: center;
      vertical-align: center;
      border-radius: 50%;
      color:  #5A7984;
      background-color: #fff;
      border: 1px solid #CCDFE5;
      transition: all .2s linear;
    }

    &:after {
      content: "Účel poskytnutí";
      font-size: 16px;
      display: none;
      transition: all .2s linear;
    }
  }

  &-listItem3 {
    opacity: 1;
    display: flex;
    color: transparent;
    transition: all .2s linear;

    &:before {
      content: "3";
      height: 42px;
      width: 42px;
      position: relative;
      top: -10px;
      margin-right: 5px;
      padding: 8px;
      display: block;
      text-align: center;
      vertical-align: center;
      border-radius: 50%;
      color:  #5A7984;
      background-color: #fff;
      border: 1px solid #CCDFE5;
      transition: all .2s linear;
    }

    &:after {
      content: "Doplňující údaje";
      font-size: 16px;
      display: none;
      width: 120px;
      transition: all .2s linear;
    }
  }

  &-listItem4 {
    opacity: 1;
    display: flex;
    color: transparent;

    &:before {
      content: "4";
      height: 42px;
      width: 42px;
      position: relative;
      top: -10px;
      margin-right: 5px;
      padding: 8px;
      display: block;
      text-align: center;
      vertical-align: center;
      border-radius: 50%;
      color:  #5A7984;
      background-color: #fff;
      border: 1px solid #CCDFE5;
      transition: all .2s linear;
    }

    &:after {
      content: "Odeslání žádosti";
      font-size: 16px;
      display: none;
      width: 130px;
      transition: all .2s linear;
    }
  }

  &-listItem5 {
    opacity: 1;
    display: flex;
    color: transparent;
    transition: all .2s linear;


    &:before {
      content: "5";
      height: 42px;
      width: 42px;
      position: relative;
      top: -10px;
      margin-right: 5px;
      padding: 8px;
      display: block;
      text-align: center;
      vertical-align: center;
      border-radius: 50%;
      color:  #5A7984;
      background-color: #fff;
      border: 1px solid #CCDFE5;
      transition: all .2s linear;
    }

    &:after {
      content: "Verifikace";
      font-size: 16px;
      display: none;
      transition: all .2s linear;
      width: 130px;
    }
  }



  .activeStatusMobile {
    color: #00B8F5;
    font-weight: bold;
    margin-right: 50px;
    transition: all .2s linear;

    &:before {
      background-color: #00B8F5;
      color: #fff;
      border-color: #00B8F5;
    }

    &:after {
      display: block;
    }
  }

  .mobileProgress-listItem2.activeStatusMobile{
    position: relative;
    width: 170px;
  }

  .mobileProgress-listItem3.activeStatusMobile{
    position: relative;
    width: 150px;

    &:after {
      position: absolute;
      width: 100%;
      left: 52px;

    }
  }

  .finishedStatusMobile {
    margin-right: 0;
    transition: all .2s linear;

    &:before {
      background-color: unset;
      background: url("assets/UserAssets/statusIcon.png");
      background-size: cover;
      color: rgba(#5A7984, .3);
      border: unset;
    }

    &:after {
      display: none;
    }
  }

  @media (max-width: 580px) {
    overflow-x: scroll;
    padding: 15px 10px;

  }
}

.mobileOnly {
  @media  (min-width: 992px) {
    display: none;
  }
}

.negative-mt-20 {
  @media (max-width: 580px) {
    margin-top: -20px;
  }
}


.Finish-container {

  //@media (min-width: 992px) {
  //  margin-top: -80px;
  //}

  .textBlackWrap {
    margin-top: -10px;
  }

  .eSpoluprace {
    opacity: 0;
    img {
      height: 1px !important;
      width: 1px !important;
    }
  }

  .lustration {
    display: flex;
    margin-right: 30px;
    font-family: Quicksand;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    color: #5A7984;

    @media (max-width: 992px) {
      flex-direction: column !important;
    }

    @media (max-width: 580px) {
      font-size: 14px;
      line-height: 26px;
      flex-direction: column;
      margin: 0;
      width: 100%;
      text-align: center;
    }

    .lustration-icon-wrap {
      @media (max-width: 580px) {
        margin: auto;
        width: 100%;
        justify-content: center;
      }
    }

    .lustration-icon {
      width: 22px;
      height: 22px;
      position: relative;
      top: 3px;
      margin-right: 7px;

      @media (max-width: 580px) {
        margin: unset;
        margin-right: 7px;
      }
    }

    .lustration-red {
      font-family: Quicksand;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 28px;
      color: #DE4444;
    }

    .lustration-green {
      font-family: Quicksand;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 28px;
      color: #27AE60;
    }

    .lustration-neutral {
      font-family: Quicksand;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 28px;
      color: #FF9900;
    }

    .lustration-green,
    .lustration-neutral,
    .lustration-red {
      @media (max-width: 580px) {
        font-size: 15px;
      }
    }

    .spinner {
      margin-left: 20px;

      @media (max-width: 580px) {
        margin: 0;
      }
    }

    .loader {
      font-size: 15px;
      margin: 0;
      position: relative;
      top: 5px;
      color: #B800F5;
    }

    .loader-text {
      display: none;
    }
  }

  h2 {
    font-family: Quicksand;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 60px;
    color: #27AE60;

  }

  .text-green {
    font-family: Quicksand;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    position: relative;
    top: 10px;
    margin-left: 15px;
    color: #27AE60;

  }

  .text-orange {
    font-family: Quicksand;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    position: relative;
    top: 10px;
    margin-left: 15px;
    color: #FF9900;

  }

  .textBlack {
    font-family: Quicksand;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    /* identical to box height, or 160% */


    /* černá */

    color: #000000;

    @media (max-width: 580px) {
      margin-bottom: 0;
      font-size: 18px;
      margin-top: 15px;
    }
  }

  //.verifyMessageBox {

    .verifyMessageSuccess-wrap {
      margin-bottom: 20px;

      img {
          width: 85px !important;
          height: 85px !important;
          margin-right: 15px;
          position: relative;
          top: -10px;
      }


      @media (max-width: 580px) {
        flex-direction: column;
        text-align: center;
      }
    }

    .verifyMessageSuccess {
      padding-top: 30px;
    }

    //img {
    //  width: 85px !important;
    //  height: 85px !important;
    //  margin-right: 15px;
    //  position: relative;
    //  top: -10px;
    //
    //  @media (max-width: 580px) {
    //    margin: auto;
    //    width: 65px;
    //    height: 65px;
    //  }
    //}

    h2 {
      font-family: Quicksand;
      @media (max-width: 580px) {
        margin: auto;
      }
    }

    h3 {
      font-family: Quicksand;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
      /* identical to box height, or 114% */


      /* černá */

      color: #000000;
      margin-bottom: 5px;
      @media (max-width: 580px) {
        font-size: 22px;
        margin-bottom: 15px;
        text-align: left !important;
      }
    }

    @media (max-width: 580px) {
      margin-bottom: 0;

    }

  }

  .cuountdown {
    max-width: 80%;
    justify-content: space-between;
    padding: 15px;
    //border: 1px solid #CCDFE5;
    border-radius: 4px;
    margin-bottom: 25px;

    p {
      position: relative;
      top: 10px;

      @media (max-width: 992px) {
        top: 0;
        text-align: center;
        margin-bottom: 15px;
        display: block;
      }
    }

    .btn-purple {
      @media (max-width: 992px) {
        top: -5px;
      }
    }
  //
  //  @media (max-width: 1410px) {
  //    max-width: 100%;
  //  }
  //
  //  @media (max-width: 992px) {
  //    flex-direction: column;
  //    max-width: 60%;
  //    margin-left: auto;
  //    margin-right: auto;
  //  }
  //
  //  @media (max-width: 580px) {
  //    max-width: 100%;
  //  }
  //}

}

.finishList {
  list-style: none;
  font-family: Quicksand;

  li {
    font-family: Quicksand;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    /* or 160% */


    /* černá */

    color: #000000;

    @media (max-width: 580px) {
      font-size: 18px;
    }
  }

  li::before {
    content: "\2022";
    color: #00B8F5;
    font-weight: bold;
    display: inline-block;
    font-size: 28px;
    width: 1em;
    margin-left: -1em;
    position: relative;
    top: 2px;
    @media (max-width: 580px) {
      font-size: 24px;
    }
  }
  //margin-bottom: 30px;

  @media (max-width: 580px) {
    text-align: left;
  }
}



.failedMessage {
  p {
    font-family: Quicksand;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 34px;
    /* or 154% */


    /* černá */

    color: #000000;

    @media (max-width: 1180px) {
      font-size: 20px;
      line-height: 30px;
    }

    @media (max-width: 580px) {
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 35px;
      text-align: center;
    }
  }
}

.Finish-loader {
  .loader {
    font-size: 16px;
    margin: 10px auto;
  }
}


.social-icons {
  @media (max-width: 992px) {
    order: 5
  }
}



.Contact-container {
  @media (min-width: 992px) {
    margin-top: 0px;
  }
  @media (max-width: 580px) {
    padding-bottom: 130px;
  }
}

.Contact {

  .verifyMessageSuccess {
    padding-top: 0 !important;
  }

  h2 {
    font-family: Quicksand;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 64px;
    /* identical to box height, or 133% */


    color: #000000;
    @media (max-width: 580px) {
      font-size: 38px;
      line-height: 44px;
      margin-top: 20px;
      text-align: center;
    }
  }

  .contact-wrap {
    margin-bottom: 50px;
    .phoneIcon {
      height: 35px;
      width: 35px;

      @media (max-width: 580px) {
        margin: unset !important;
        margin-top: 10px !important;
        margin-left: 15px !important;
      }

      @media (max-width: 360px) {
        margin-left: 5px !important;
      }

    }

    a {
      font-family: Quicksand;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
      /* identical to box height, or 114% */


      /* černá */

      color: #000000;
      @media (max-width: 580px) {
        font-size: 22px;
        line-height: 34px;

      }
    }

    span {
      font-family: Quicksand;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 32px;
      /* identical to box height, or 160% */


      /* šedá */

      color: #5A7984;
      @media (max-width: 580px) {
        font-size: 18px;
        line-height: 30px;

      }
    }

    @media (max-width: 580px) {
      justify-content: center;
      margin-bottom: 30px;
    }
  }

  .contactAddress {
    font-family: Quicksand;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    /* or 160% */


    /* černá */

    color: #000000;

    @media (max-width: 580px) {
      text-align: center;
    }
  }

  .ico {
    margin-top: 30px;
    p {
      font-family: Quicksand;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 32px;
      /* identical to box height, or 160% */


      /* černá */

      color: #000000;

    }
    @media (max-width: 580px) {
      text-align: center;
    }

    .small-text {
      font-family: Quicksand;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      /* or 150% */


      /* šedá */

      color: #5A7984;

      @media (max-width: 580px) {
        text-align: center !important;
      }
    }

    @media (max-width: 580px) {
      margin-top: 20px;

      br {
        display: none;
      }
    }
  }

  .mobileBodyWrap {
    //height: -webkit-fill-available;
    @media (max-width: 992px) {
      margin-top: 50px;
    }
  }

  .phoneBody {
    //background: url("../../assets/phoneBody.png") no-repeat;
    //background-size: cover;
    //max-height: 700px;
    //height: 100%;

    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    max-height: 700px;
    height: 100%;
    width: 100%;
    max-width: 360px;

    @media (max-width: 992px) {
      width: 50%;
      left: 50%;
      transform: translateX(-50%);
    }

    @media (max-width: 580px) {
      width: 90%;
      left: 50%;
      transform: translateX(-50%);
    }

    //@media (max-width: 360px) {
    //  left: -55px;
    //}
  }

  .mobileBody {
    position: relative;
    z-index: 4;
    padding: 45px 5px;
    //padding-top: 75px;
    //padding-left: 55px;
    height: 100%;
    width: 100%;

    @media (max-width: 992px) {
      width: 50%;
      margin-left: auto;
      margin-right: auto;
    }

    @media (max-width: 580px) {
      width: 90%;
    }

    form {
      width: 100%;
      max-width: 320px;
      @media (max-width: 580px) {
        margin: auto;
      }
    }

    h3 {
      font-family: Quicksand;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 34px;
      /* identical to box height, or 143% */

      text-align: center;

      /* modrá */

      color: #00B8F5;

      @media (max-width: 580px) {
        width: 70%;
        margin: auto;
      }
    }

    input,
    textarea {
      resize: none;
      background: #FFFFFF;
      /* šedá 2 */

      border: 1px solid #CCDFE5;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 5px 0px 5px 5px;
    }

    textarea {
      width: 100%;
      @media (max-width: 580px) {
        width: 100%;
      }
    }

    input {
      height: 40px;
      width: 100%;
      margin-top: 20px;
      padding: 5px 5px 5px 15px;
      @media (max-width: 580px) {
        width: 100%;
      }
    }

    .btn-purple {
      width: 150px;
      margin: auto;
      display: block;
      position: relative;
      //left: -15px;
      margin-top: 25px;
      @media (max-width: 992px) {
        top: 0 !important;
      }

      @media (max-width: 580px) {
        top: 0;
        left: -5px;
      }
    }

    @media (max-width: 580px) {
      padding-left: 0;
    }
    @media (max-width: 360px) {
      padding-left: 0px;
    }
  }



  .contact-success,
  .contact-error {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;

    @media (max-width: 580px) {
      position: relative;
      top: unset;
      transform: none;
      margin-left: -20px;
      margin-top: 30px;
      width: 100%;
    }
  }

  .contact-error {
    color: red;
  }

}

.backArrow-contact {
  opacity: .5;
  transition: all .2s ease-in-out;

  &:hover {
    opacity: 1;
  }
}


.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #B800F5;
}



input:focus + .slider {
  //box-shadow: 0 0 1px #B800F5;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.opacity-50 {
  opacity: 0.5;
}

.reset-top-0 {
  top: 0 !important;
  @media (max-width: 992px) {
    margin: 0 !important;
  }
}


.Cookies {
  font-family: Quicksand;
  font-style: normal;
  position: fixed;
  bottom: 0;
  background: #fbfbfb;
  left: 0;
  width: 100%;
  z-index: 10;
  padding: 25px;
  text-align: center;
  box-shadow: 0 0 20px rgba(0, 0, 0, .15);

  img {
    height: 100%;
    width: auto;
    margin-right: 10px;
    //max-height: 80px;
  }

  .btn-purple-cookies {
    padding: 16px 32px !important;
    line-height: 16px !important;
    font-size: 13px !important;
  }

  .btn-cookies {
    line-height: 16px !important;

    @media (max-width: 585px) {
      margin: unset !important;
    }
  }

  .btn-main-cookies {
    margin-bottom: 0 !important;

  }

  .decline-link {
    text-decoration: underline;
    color: #0c8fdf;
    cursor: pointer;
  }
}

.CookiesModal {
  font-family: Quicksand;
  font-style: normal;

  img {
    height: 100%;
    width: auto;
  }

  .btn-main {
    margin: unset !important;
      margin-right: 10px !important;
  }
}

.CookieRow {
  font-family: Quicksand;
  font-size: 16px;

  margin-bottom: 25px;
  h2 {
    font-family: Quicksand;
    font-style: normal;
    font-size: 20px;
    line-height: 28px;
    //text-align: center;
    color: #000000;
  }

}

.VerificationOfferModal {
  b {
    font-size: 16px;
  }
}

.normal-text {
  font-style: normal !important;
  @media (min-width: 1680px) {
    padding-left: 88px !important;

  }

  @media (max-width: 1480px) {
    padding-left: 70px !important;

  }
}

.autocomplete {
  background-color: #fff;
  padding: 10px 15px;
  border-left: 1px solid #CCDFE5;
  border-right: 1px solid #CCDFE5;
  border-bottom: 1px solid #CCDFE5;
  cursor: pointer;

  &:hover {
    background-color: #DFF7FF;
  }
}

.address-error {
    opacity: 1;
    margin-top: 10px;
    margin-bottom: 0;

  @media (max-width: 585px) {
    width: 80%;
  }
}

.personalDataAgreement {
    margin-bottom: 0!important;

  @media (max-width: 585px) {
    margin-top: 15px !important;

  }

    h2 {
      font-size: 20px !important;

      @media (max-width: 585px) {
        text-align: center !important;
      }
    }

    li::before,
    li::after {
      display: none !important;

    }

    li {
      border: none !important;
    }


}

.verifyImage {
  position: absolute;
  bottom: -40px;
  left: -20px;
  max-height: 250px;


  @media (max-width: 585px) {
    max-height: 140px;
  }
}

.page__section-title {
  font-family: Quicksand;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 35px;
  text-align: center;

  /* identical to box height */


  color: #000000;

  @media (max-width: 1480px) {
    font-size: 22px !important;
  }

  @media (max-width: 580px) {
    font-size: 18px;
    line-height: 26px;
  }
}

.decline-btn {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;

  /* identical to box height, or 175% */
  text-align: center;
  text-decoration-line: underline;
  color: #5A7984;
  margin-top: 30px;
  cursor: pointer;
  transition: all .2s linear;

  &:hover {
    color: #B800F5;
  }
}
